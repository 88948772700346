import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
declare var jQuery: any;
declare var IN: any;
import { MetaService } from "./meta.service";
import { LinkService } from "./link.service";

//import { CookieService } from "angular2-cookie/services/cookies.service";
//import { CookieService } from "angular2-cookie/core";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

/* Live setting */

//const crmurl = "https://www.intercellworld.com/crm/";
//const baseurl ="https://www.intercellworld.com/";
//const videoScreenUrl = "https://www.intercellworld.com/assets/scripts/forward.php";

/* for Dev */

//const crmurl = "https://www.intercellworld.com/crmd/";
//const baseurl ="http://test.intercellworld.com/";
//const videoScreenUrl = "https://www.intercellworld.com/assets/scripts/forward_test.php";

/* for testing */

const crmurl = "https://www.intercellworld.com/prod/";
//const baseurl = "https://test.intercellworld.com/";
const baseurl = window.location.origin + "/";
const videoScreenUrl =
  "https://www.intercellworld.com/assets/scripts/forward_prod.php";

const ImageUploadurl = crmurl + "theme/site/uploads/";
const Imageurl = crmurl + "theme/site/uploads/users/";
const Imageurlicard = crmurl + "theme/site/uploads/icard/";
const Imageurlattach = crmurl + "theme/site/uploads/attachment/";
const crmbaseurl = crmurl + "webin/";
const paymentUrl = crmurl + "web/";

declare global {
  interface String {
    ucwords(): string;
  }
}

String.prototype.ucwords = function () {
  let str = this.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function ($1) {
    return $1.toUpperCase();
  });
};

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function createCookie(cookieName, cookieValue, daysToExpire) {
  var date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie =
    cookieName + "=" + cookieValue + "; expires=" + date.toUTCString();
}

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(
    private http: HttpClient,
    private metaSer: MetaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private linkService: LinkService,
    @Inject(DOCUMENT) private doc
  ) {
    //this.loader('1s');
    /* if(!getCookie("setuserloginval")){
      localStorage.removeItem('user');
      localStorage.removeItem('userPic');
      localStorage.removeItem('Userfullname');
      localStorage.removeItem('msgcount');
     }*/
  }

  /*myOptions(){
    let myOptions = [
      {id: '', name: "Select Your Level Of Education"},
      {id: 2, name: "Senior Secondary or Below"},
      {id: 3, name: "Graduate"},
      {id: 4, name: "Postgraduate"},
      {id: 5, name: "Others"},
    ];
    return  myOptions;
    
  }
*/
  myOptions() {
    let myOptions = [
      { id: "", name: "Select Current Level Of Education" },
      { id: 2, name: "School" },
      { id: 3, name: "Graduate" },
      { id: 4, name: "Postgraduate" },
      { id: 5, name: "Others" },
    ];
    return myOptions;
  }

  GenderOptions() {
    let GenderOptions = [
      { id: "", name: "Select Gender" },
      { id: "Male", name: "Male" },
      { id: "Female", name: "Female" },
    ];
    return GenderOptions;
  }

  MenteeOptions() {
    let MenteeOptions = [
      { id: "", name: "Select Mentee Type" },
      { id: "0", name: "Student" },
      { id: "1", name: "Professional" },
    ];
    return MenteeOptions;
  }

  MonthOption() {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Septmeber",
      "October",
      "November",
      "December",
    ];
  }

  StringtoLimit(str, limit = 0) {
    if (str.length > limit) {
      str = str.slice(0, limit) + "...";
    } else {
      str = str;
    }
    return str;
  }

  Fullname(fname, lname) {
    let FN = fname.charAt(0).toUpperCase() + fname.slice(1);
    let LN = lname.charAt(0).toUpperCase() + lname.slice(1);
    let fullname: any = FN + " " + LN;
    if (fullname.length > 39) {
      fullname = fullname.slice(0, 40) + "...";
    } else {
      fullname = fullname;
    }
    return fullname;
  }

  FullnameRefer(fname, lname) {
    let FN = fname.charAt(0).toUpperCase() + fname.slice(1);
    let LN = lname.charAt(0).toUpperCase() + lname.slice(1);
    let fullname: any = FN + " " + LN;
    if (fullname.length > 15) {
      fullname = fullname.slice(0, 15) + "...";
    } else {
      fullname = fullname;
    }
    return fullname;
  }

  YearOption() {
    let yy;
    let years: any = [];
    let today = new Date();
    yy = today.getFullYear();
    for (var i = yy - 48; i <= yy; i++) {
      years.push(i);
    }
    return years;
  }
  YearOptions() {
    let yy;
    let years: any = [];
    let today = new Date();
    yy = today.getFullYear();
    for (var i = yy - 48; i <= yy + 5; i++) {
      years.push(i);
    }
    return years;
  }

  getexpProYear() {
    let expProyears: any = [];
    for (var i = 1; i <= 50; i++) {
      expProyears.push(i);
    }

    return expProyears;
  }

  videoScreenServer() {
    return videoScreenUrl;
  }

  PaymentUrl() {
    return paymentUrl + "pay_session";
  }

  ShowStatus(status) {
    let result =
      status == "Payment Pending"
        ? "Awaiting confirmation from Mentee"
        : status;
    return result;
  }

  ShowStatuswithUser(status, usertype) {
    let result = "";
    if (status == "Payment Pending" && usertype == 3) {
      result = "Awaiting confirmation from Mentee";
    } else {
      result = status;
    }

    return result;
  }

  /*  page loaders  */
  loader($type = "call") {
    if ($type == "call" || $type == "c") {
      return localStorage.getItem("headLoader") ? true : false;
    }
    if ($type == "set" || $type == "s") {
      localStorage.setItem("headLoader", "1");
    }
    if ($type == "remove" || $type == "r") {
      localStorage.removeItem("headLoader");
    }

    /* on call globalel service it will set or remove for 1 s, if defoult set on site then it will remove after 1s */
    if ($type == "1s") {
      localStorage.setItem("headLoader", "1");
      setTimeout(() => {
        localStorage.removeItem("headLoader");
      }, 1000);
    }
  }

  pageLoader() {
    let body = <HTMLDivElement>document.head;
    let loader = document.createElement("div");
    loader.className = "loader";
    body.after(loader);
    setTimeout(() => {
      loader.className = "";
      body.after(loader);
    }, 1000);
  }

  meta($page: any = "", $title: any = false, $meta: any = false) {
    this.linkService.addTag({
      rel: "canonical",
      href: this.getUrl("live") + this.router.url,
    });
    this.metaSer.metaData($page, $title, $meta);
    this.pageName("set", $page);
    this.pageLoader();

    //this.createLinkForCanonicalURL();
  }

  getimageurl(image) {
    image = image ? image : "default.jpg";
    return Imageurl + image;
  }
  getattachment(image) {
    return Imageurlattach + image;
  }

  getimageurlicard(image) {
    return Imageurlicard + image;
  }

  getImageUploadurl() {
    return ImageUploadurl;
  }

  PreCallTest() {
    return "https://www.intercellworld.com/assets/scripts/pre_call_test.html";
  }

  pageName($type = "get", $page = "") {
    if ($type == "set") {
      localStorage.setItem("pageName", $page);
    }
    if ($type == "get") {
      return localStorage.getItem("pageName");
    }
  }

  getUrl(type = "base") {
    let urls = {
      base: baseurl,
      crm: crmurl,
      live: "https://www.intercellworld.com",
    };
    return urls[type];
  }

  getUrlCarrerImageUrl(imageName, type = "base") {
    let imageNameUrl =
      imageName.indexOf("assets") > -1
        ? imageName
        : `assets/images/${imageName}`;
    return `${this.getUrl(type)}${imageNameUrl}`;
  }

  js(urls) {
    for (var i = 0; i < urls.length; i++) {
      let body = <HTMLDivElement>document.body;
      let script = document.createElement("script");
      script.src = baseurl + urls[i];
      script.type = "text/javascript";
      body.appendChild(script);
    }
  }

  css(urls) {
    for (var i = 0; i < urls.length; i++) {
      let body = <HTMLDivElement>document.head;
      let style = document.createElement("link");
      style.href = baseurl + urls[i];
      style.rel = "stylesheet";
      body.appendChild(style);
    }
  }

  formErrors(rpform, formRpErrors, validationMessages) {
    for (let field in formRpErrors) {
      formRpErrors[field] = "";
      let input = rpform.get(field);
      if (input.invalid) {
        for (let error in input.errors) {
          formRpErrors[field] = validationMessages[field][error];
        }
      }
    }

    return formRpErrors;
  }

  blankJson(jsons) {
    var obj = {};
    Object.keys(jsons).forEach(function (v) {
      obj[v] = null;
    });
    return obj;
  }

  typeNumberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  redirect(url: any = "/") {
    this.router.navigate([url]);
  }

  /* auth methods */

  setLogin(data: any, redirect: any = "/") {
    // sessionStorage.setItem('setuserloginval', 'yes');
    //createCookie('setuserloginval','yes','1');
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("userPic", data.dp_image);
    localStorage.setItem("Userfullname", data.fullname);
    localStorage.setItem("msgcount", data.msgcount);

    if (redirect) {
      // window.location.replace(redirect);
      this.router.navigate([redirect]);
    }
  }

  currentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  isLoggedIn() {
    return this.currentUser() ? true : false;
  }

  getUserOrganisationInfo() {
    let userInfo = localStorage.getItem("user");
    if (userInfo) {
      let userData = JSON.parse(userInfo);
      if (!userData.is_default_user) {
        return {
          valid: true,
          company_name: userData.company_name,
          company_logo: userData.company_logo,
          userId: userData.id,
        };
      } else {
        return { valid: false };
      }
    } else {
      return { valid: false };
    }
  }

  logout(redirect: any = "/") {
    this.loader("1s");
    localStorage.removeItem("user");
    localStorage.removeItem("userPic");
    localStorage.removeItem("Userfullname");
    localStorage.removeItem("msgcount");
    //sessionStorage.removeItem('setuserloginval');

    if (redirect) {
      //window.location.replace(redirect);
      this.router.navigate([redirect]);
    }
  }

  ngClassForColorsection(value) {
    let data = "";
    if (value == "Rejected" || value == "Cancelled") {
      data = "text-red";
    } else {
      data = "text-green";
    }
    return data;
  }

  MergeRecursive(obj1, obj2) {
    for (var p in obj2) {
      try {
        if (obj2[p].constructor == Object) {
          obj1[p] = this.MergeRecursive(obj1[p], obj2[p]);
        } else {
          obj1[p] = obj2[p];
        }
      } catch (e) {
        obj1[p] = obj2[p];
      }
    }
    return obj1;
  }

  globaljs() {
    this.js(["assets/js/global.js"]);
  }

  selectedCheckboxVal(option, event, selectedArr, options) {
    var selectedCategories = selectedArr;
    if (event.target.checked) {
      selectedCategories.push(option.id);
    } else {
      for (var i = 0; i < options.length; i++) {
        if (selectedCategories[i] == option.id) {
          selectedCategories.splice(i, 1);
        }
      }
    }
    return selectedCategories;
  }

  /* web api  */
  urls = {
    login: crmbaseurl + "login",
    forgotPassword: crmbaseurl + "forgotpassword",
    ValidateResetHash: crmbaseurl + "validateresethash",
    resetPassword: crmbaseurl + "resetpassword",
    signUp: crmbaseurl + "signup",
    icdevops: crmbaseurl + "icdevops",
    dpImgUpload: crmbaseurl + "dpImgUpload",
    careerDetails: crmbaseurl + "careerDetails",
    featuredMentor: crmbaseurl + "featuredMentor",
    campaignMentor: crmbaseurl + "campaignMentor",
    filteredMentor: crmbaseurl + "filteredMentor",
    profile: crmbaseurl + "profile",
    socialLogin: crmbaseurl + "socialLogin",
    profileReviews: crmbaseurl + "profileReviews",
    faq: crmbaseurl + "faqs",
    listsServices: crmbaseurl + "listsServices",
    searchMentorlists: crmbaseurl + "searchMentorlists",
    Contactus: crmbaseurl + "Contactus",
    need_help: crmbaseurl + "need_help",
    session_history: crmbaseurl + "session_history",
    notificationsList: crmbaseurl + "notificationsList",
    getavailability: crmbaseurl + "getavailability",
    CheckAndSendCode: crmbaseurl + "CheckAndSendCode",
    CheckAndSendCodesocial: crmbaseurl + "CheckAndSendCodesocial",
    checkstdcode_ipaddress: crmbaseurl + "checkstdcode_ipaddress",
    CheckOTPAndUpdate: crmbaseurl + "CheckOTPAndUpdate",
    ResendCode: crmbaseurl + "ResendCode",
    countries: crmbaseurl + "countries",
    resetPasswordWithLogin: crmbaseurl + "resetPasswordWithLogin",
    MentorbyFilter: crmbaseurl + "MentorbyFilter",
    MrSlotListShowMe: crmbaseurl + "MrSlotListShowMe",
    memberprofile: crmbaseurl + "memberprofile",
    mreducation: crmbaseurl + "mreducation",
    mrbankdetails: crmbaseurl + "mrbankdetails",
    mrEditProfile: crmbaseurl + "mrEditProfile",
    mraward: crmbaseurl + "mraward",
    meEditProfile: crmbaseurl + "meEditProfile",
    meeducation: crmbaseurl + "meeducation",
    upcomming: crmbaseurl + "upcomming",
    exclusive: crmbaseurl + "exclusive",
    sessiondetailsview: crmbaseurl + "sessiondetailsview",
    sessiondetailsWithToken: crmbaseurl + "sessiondetailsWithToken",
    sessionaction: crmbaseurl + "sessionaction",
    setavailability: crmbaseurl + "setavailability",
    BookSessionMentee: crmbaseurl + "BookSessionMentee",
    OTPSendWithoutLogin: crmbaseurl + "OTPSendWithoutLogin",
    ResetPwdWithoutLogin: crmbaseurl + "ResetPwdWithoutLogin",
    referral: crmbaseurl + "referral",
    updateProfileimage: crmbaseurl + "updateProfileimage",
    deleteProfileimage: crmbaseurl + "deleteProfileimage",
    UpdateMessageUpdate: crmbaseurl + "UpdateMessageUpdate",
    AwardDelete: crmbaseurl + "AwardDelete",
    CheckOTP: crmbaseurl + "CheckOTP",
    meUpdateExperience: crmbaseurl + "meUpdateExperience",
    upload_base64_image: crmbaseurl + "upload_base64_image",
    teaser_video_get: crmbaseurl + "teaser_video_get",
    jobRecommendation: crmbaseurl + "jobRecommendation",
    checkcardstatus: crmbaseurl + "checkcardstatus",
    file_download: crmbaseurl + "file_download",
    association: crmbaseurl + "association",
    logreferral: crmbaseurl + "logreferral",
    exclusivepartners: crmbaseurl + "exclusivepartners",
    mentee_feedback: crmbaseurl + "mentee_feedback",

    getRecommendedMentor: crmbaseurl + "getRecommendedMentor",

    mentorRecommendation: crmbaseurl + "getRecommendedMentor",
    changeuser: crmbaseurl + "changeuser",
    pageTracking: crmbaseurl + "page_tracking",
    talentFinderAttribute: crmbaseurl + "talent_finder_attribute",
    talentFinderQuestion: crmbaseurl + "talent_finder_question",
    talentFinderQuestion2: crmbaseurl + "talent_finder_question_2",
    talentFinderCategory: crmbaseurl + "talent_finder_category",
    talentFinderMentor: crmbaseurl + "get_mentor_by_cat_id",
    talentFinderStarted: crmbaseurl + "talent_finder_started",
    talentFinderAttributeCategory:
      crmbaseurl + "talent_finder_attribute_category",
    talentFinderAttributeSave: crmbaseurl + "talent_finder_data_attribute_save",
    talentFinderQuestion1Save: crmbaseurl + "talent_finder_question1_save",
    talentFinderQuestion2Save: crmbaseurl + "talent_finder_question2_save",
    updateTalentSave: crmbaseurl + "update_talent_steps",
    talentAttributeInfo: crmbaseurl + "talent_finder_attribute_info",
    changebankname:
      "https://api.bincodes.com/cc/json/6d921508274df6cc71b7e8d13cf31f77/4854980801219900/",
    deleteUserProfile: crmbaseurl + "deleteuser",
    memberprofilestatus: crmbaseurl + "user_profile_status",
    generate_captcha: crmbaseurl + "generate_captcha",
    g_calendar_link: crmbaseurl + "g_calendar_link",
    delete_g_calendar: crmbaseurl + "delete_g_calendar",
    g_calendar: crmbaseurl + "g_calendar",
    linkedinInfo: crmbaseurl + "linkedinInfo",
    MrSlotListShowMentor: crmbaseurl + "MrSlotListShowMentor",
    CheckAndSendCodelogin: crmbaseurl + "CheckAndSendCodelogin",
    CheckAccountExist: crmbaseurl + "CheckAccountExist",
    loginwithOtp: crmbaseurl + "loginwithOtp",
    checkaudio: crmbaseurl + "checkaudio",
    checkuserfordna: crmbaseurl + "checkuserfordna",
    GetDNAquestions: crmbaseurl + "GetDNAquestions",
    submitDNAquestions: crmbaseurl + "submitDNAquestions",
    checkCurrentDNAstep: crmbaseurl + "checkCurrentDNAstep",
    generateAndDownloadDNApdf: crmbaseurl + "generateAndDownloadDNApdf",
    getHelpArr: crmbaseurl + "getHelpArr",
    saveAskForMentorRecommendation:
      crmbaseurl + "saveAskForMentorRecommendation",
  };

  webapi(type, data: any = {}, header: any = false) {
    var httpHeader = header ? header : httpOptions;
    let userOrganisationInfo = this.getUserOrganisationInfo();
    if (userOrganisationInfo.valid) {
      data.globalUserId = userOrganisationInfo.userId;
    }
    return this.http.post(this.urls[type], data, httpHeader);
  }

  webapi2(type, data: any = {}, header: any = false) {
    var httpHeader = header ? header : httpOptions;
    let userOrganisationInfo = this.getUserOrganisationInfo();
    if (userOrganisationInfo.valid) {
      data.globalUserId = userOrganisationInfo.userId;
    }
    return this.http.post(
      "https://intercellworld.konnectnxt.com/api/login-with-token",
      data,
      httpHeader
    );
  }

  getimageurlPath(image) {
    return Imageurl + image;
  }

  getSessionDetailUrl(session_id) {
    return baseurl + "account/upcoming/" + session_id;
  }

  getPaymentDetailUrl(session_id) {
    return baseurl + "account/paymentnow/" + session_id;
  }

  getParameterValueByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  getdatefun = function (number) {
    var date = new Date();
    var newdate = new Date(date);
    newdate.setDate(newdate.getDate() + number);
    var dd = newdate.getDate();
    var mm = newdate.getMonth() + 1;
    var y = newdate.getFullYear();
    var dd1: any;
    var mm1: any;
    if (dd < 10) {
      dd1 = "0" + dd;
    } else {
      dd1 = dd;
    }
    if (mm < 10) {
      mm1 = "0" + mm;
    } else {
      mm1 = mm;
    }
    var someFormattedDate = y + "-" + mm1 + "-" + dd1;
    return someFormattedDate;
  };

  UserProfileUrl = function (user) {
    var str: any = user.first_name + " " + user.last_name;
    str = str.replace(/\s+/g, "-").toLowerCase();
    var url = this.getUrl("base") + "mentor/" + str + "-ic" + user.id;
    return url;
  };

  createLinkForCanonicalURL() {
    if (this.doc.querySelectorAll("link[rel=canonical]").length > 0) {
      this.doc.querySelector("link[rel=canonical]").href = this.doc.URL;
    } else {
      let link: HTMLLinkElement = this.doc.createElement("link");
      link.setAttribute("rel", "canonical");
      this.doc.head.appendChild(link);
      link.setAttribute("href", this.doc.URL);
    }
  }

  disabledbtn = function (id) {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    jQuery("#" + id)
      .attr("disabled", "disabled")
      .html("Processing...");
  };

  createCookies(cookieName, cookieValue, daysToExpire) {
    var date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    document.cookie =
      cookieName + "=" + cookieValue + "; expires=" + date.toUTCString();
  }

  getCookies(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
